import React from 'react';
import { useRouter } from 'next/router';

import { hexToRgb, rgbDataURL } from 'corporate-utils';
import { useTheme } from '../../../theme';
import { getStrapiMedia } from '../../../utils/media';
import { getAvailableStringFromCategoryByLocale } from '../../../utils/categories';
import { Category as CategoryModel } from '../../../models/domain/interfaces';

import {
  Block,
  CardStyled,
  Category,
  ImageWrapper,
  Name,
  ImageOverflow,
} from './CardSliderProducts.style';
import { WrapComponentToLink } from '../../wrap-component-to-link/WrapComponentToLink';
import { DEFAULT_IMAGE } from '../../../models/domain/const';
import ImageWithFallback from '../../image-with-fallback/ImageWithFallback';

interface CardSliderProductsProps {
  data: {
    category: string;
    name: string;
    button: any;
    image: string;
    categoriesCalculatedInfo: CategoryModel[][];
  };
  isPrerenderRequest: boolean;
}

const CardSliderProducts: React.FC<CardSliderProductsProps> = (
  props: CardSliderProductsProps,
  isPrerenderRequest: boolean
) => {
  const { category, name, image, button, categoriesCalculatedInfo } =
    props?.data || {};
  const theme = useTheme();
  const router = useRouter();

  const mainCategoriesCalculatedInfo = categoriesCalculatedInfo?.[0];
  const firstCategory =
    mainCategoriesCalculatedInfo?.[0] &&
    getAvailableStringFromCategoryByLocale(
      mainCategoriesCalculatedInfo?.[0],
      router.locale,
      'packages/corporate-ui/src/components/cards/card-other-products/CardOtherProducts.tsx'
    );
  const secondCategory =
    mainCategoriesCalculatedInfo?.[0] &&
    mainCategoriesCalculatedInfo?.[1] &&
    getAvailableStringFromCategoryByLocale(
      mainCategoriesCalculatedInfo?.[mainCategoriesCalculatedInfo?.[2] ? 1 : 0],
      router.locale,
      'packages/corporate-ui/src/components/cards/card-other-products/CardOtherProducts.tsx'
    );
  const thirdCategory =
    mainCategoriesCalculatedInfo?.[1] &&
    getAvailableStringFromCategoryByLocale(
      mainCategoriesCalculatedInfo?.[mainCategoriesCalculatedInfo?.[2] ? 2 : 1],
      router.locale,
      'packages/corporate-ui/src/components/cards/card-other-products/CardOtherProducts.tsx'
    );

  const handleClick = (e: any) => {
    e.preventDefault();
    if (button.link) {
      router.push(
        { pathname: button.link },
        { pathname: button.link },
        { locale: router.locale, shallow: false }
      );
    }
  };

  return (
    <CardStyled
      onClick={(e: any) => {
        handleClick(e);
      }}
    >
      <Block isHeader>
        {(category || firstCategory) && (
          <Category>{category || firstCategory}</Category>
        )}
        {name && (
          <Name>
            <WrapComponentToLink href={button?.link}>
              {name}
            </WrapComponentToLink>
          </Name>
        )}
        {image && typeof image === 'string' && (
          <ImageOverflow>
            <ImageWrapper>
              <ImageWithFallback
                layout="intrinsic"
                width={theme.state.theme.spacings.unit(62.5)}
                height={theme.state.theme.spacings.unit(62.5)}
                objectFit="contain"
                placeholder="blur"
                blurDataURL={rgbDataURL(
                  hexToRgb('ffffff')?.[0],
                  hexToRgb('ffffff')?.[1],
                  hexToRgb('ffffff')?.[2]
                )}
                src={getStrapiMedia(image ?? '')}
                alt={`${secondCategory ?? ''} ${secondCategory ? '-' : ''} ${
                  thirdCategory ?? ''
                } ${thirdCategory ? '-' : ''} ${name ?? ''} ${
                  name ? '-' : ''
                } ${process?.env?.NEXT_PUBLIC_COMPANY_NAME ?? ''}`}
                fallbackSrc={DEFAULT_IMAGE}
                isPrerenderRequest={isPrerenderRequest}
              />
            </ImageWrapper>
          </ImageOverflow>
        )}
      </Block>
    </CardStyled>
  );
};

export default CardSliderProducts;
