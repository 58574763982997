import { styled } from '@mui/material';
import {
  HideOnMobile,
  MobilePaddingLessContentWrapper,
  StyledLink,
} from '../../../styles/shared';

export const StyledContentWrapper = styled(MobilePaddingLessContentWrapper)(
  ({ theme }) => ({
    marginBottom: theme.spacings.unit(16),
  })
);

export const CarouselWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpointsMediaQuery.up('md')]: {
    minHeight: theme.spacings.unit(500 / 4),
    flexDirection: 'row',
  },
}));

export const SliderItem = styled('div')(({ theme }) => ({
  width: '100%',
  [theme.breakpointsMediaQuery.up('md')]: {
    width: '65%',
  },
}));

export const LeftImageHideOnMobile = styled(HideOnMobile)(({ theme }) => ({
  display: 'none',
  [theme.breakpointsMediaQuery.up('md')]: {
    display: 'block',
    width: '100%',
  },
}));

export const LeftImage = styled('div')(({ theme }) => ({
  width: '100%',
  [theme.breakpointsMediaQuery.up('md')]: {
    width: '35%',
  },
  [theme.breakpointsMediaQuery.up('xl')]: {
    width: '100%',
  },
}));

export const Category = styled('h6')(({ theme }) => ({
  textTransform: 'uppercase',
  color: theme.colors.white,
  margin: `${theme.spacings.unit(1)} 0`,
}));

export const Title = styled('p')(({ theme }) => ({
  color: theme.colors.white,
  fontSize: theme.fonts.utility.size(14),
  margin: `${theme.spacings.unit(1)} 0`,
}));

export const CTA = styled(StyledLink)(({ theme }) => ({
  marginTop: theme.spacings.unit(8),
  fontSize: theme.fonts.utility.size(7),
  color: theme.colors.white,
}));
