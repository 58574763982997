import React from 'react';

// Components
import CarouselProdPromo from '../../../components/carousel/carousel-prod-promo/CarouselProdPromo';
import Card from '../../../components/cards/card/Card';

// Models
import { Product, SliderConfigs } from '../../../models/domain/interfaces';

// Style
import {
  CarouselWrapper,
  LeftImage,
  SliderItem,
  StyledContentWrapper,
} from './SliderProducts.style';
import SectionHeader from '../../../components/section-header/SectionHeader';
import { defaultSliderConfigs } from 'packages/corporate-ui/src/utils/carousels';

interface SliderProductsProps {
  card: any;
  mainTitle: string;
  title: string;
  mainTitleFontSize?: number;
  titleFontSize?: number;
  mainTitleMobileFontSize?: number;
  titleMobileFontSize?: number;
  titleMobileFontWeight?: number;
  titleFontWeight?: number;
  mainTitleMobileFontWeight?: number;
  mainTitleFontWeight?: number;
  size: any;
  products: Product[];
  sliderConfigs?: SliderConfigs;
}

const SliderProducts: React.FC<any> = ({
  data,
  translations,
  isPrerenderRequest,
}: {
  data: SliderProductsProps;
  translations: any;
  isPrerenderRequest: boolean;
}) => {
  const products =
    data &&
    data?.products &&
    Array.isArray(data?.products) &&
    data?.products?.map((product) => ({
      title: product?.nameFather ?? '',
      category: product?.category ?? '',
      button: {
        text: translations?.pages?.home?.productsSlider?.showProduct,
        link: product?.link || '',
      },
      image: product?.images?.length ? product?.images?.[0] : [],
      categoriesCalculatedInfo: product?.categoriesCalculatedInfo,
    }));

  const { title, category, description, button, overlayColor, image } =
    data.card || {};

  const card = {
    title,
    category,
    description,
    button: { text: button?.text, link: button?.url?.split('//').join('/') },
    overlayColor,
    image: image,
    isPrerenderRequest: isPrerenderRequest,
  };

  return (
    <StyledContentWrapper>
      <SectionHeader
        subtitle={data?.mainTitle ?? ''}
        title={data?.title ?? ''}
        titleFontSize={data?.titleFontSize}
        mainTitleFontSize={data?.mainTitleFontSize}
        titleMobileFontSize={data?.titleMobileFontSize}
        mainTitleMobileFontSize={data?.mainTitleMobileFontSize}
        mainTitleFontWeight={data?.mainTitleFontWeight}
        mainTitleMobileFontWeight={data?.mainTitleMobileFontWeight}
        titleFontWeight={data?.titleFontWeight}
        titleMobileFontWeight={data?.titleMobileFontWeight}
      />
      <CarouselWrapper>
        <LeftImage>
          <Card
            section="slider-products"
            data={card}
            isPrerenderRequest={isPrerenderRequest}
          />
        </LeftImage>
        <SliderItem>
          <CarouselProdPromo
            cards={products as any}
            sliderConfigs={data?.sliderConfigs ?? defaultSliderConfigs}
            isPrerenderRequest={isPrerenderRequest}
          />
        </SliderItem>
      </CarouselWrapper>
    </StyledContentWrapper>
  );
};

export default SliderProducts;
