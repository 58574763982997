import { styled } from '@mui/material';
import { VisibilityIcon } from '../../../assets';
import { StyledLink } from '../../../styles/shared';

export const CardStyled = styled('div')(({ theme }) => ({
  backgroundColor: theme.colors.secondaryBackground,
  padding: 0,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  '.MuiDivider-root': {
    flexGrow: 2,
  },
  cursor: 'pointer',
  [theme.breakpointsMediaQuery.up('md')]: {
    backgroundColor: 'transparent',
  },
}));

export const Block = styled('div', {
  shouldForwardProp: (prop) => !['isHeader'].includes(prop as string),
})(({ theme, isHeader }) => ({
  padding: `0 ${theme.spacings.unit(4)}`,
  ...(isHeader && {
    flexGrow: 2,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  }),
  [theme.breakpointsMediaQuery.up('md')]: {
    padding: 0,
  },
}));

export const Category = styled('h2')(({ theme }) => ({
  color: theme.colors.primary,
  textTransform: 'uppercase',
  marginBottom: 0,
  fontSize: theme.fonts.utility.size(6),
  marginTop: theme.spacings.unit(4.25),
  [theme.breakpointsMediaQuery.up('md')]: {
    marginTop: theme.spacings.unit(10),
    fontSize: theme.fonts.utility.size(7),
  },
}));

export const Name = styled('h2')(({ theme }) => ({
  color: theme.colors.secondaryText,
  textOverflow: 'ellipsis',
  fontWeight: '300',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  marginTop: theme.spacings.unit(1),
  fontSize: theme.fonts.utility.size(10),
  [theme.breakpointsMediaQuery.up('md')]: {
    fontSize: theme.fonts.utility.size(11),
  },
}));

export const ImageOverflow = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  flex: 1,
}));

export const ImageWrapper = styled('div')(({ theme }) => ({
  margin: '0 auto',
  maxWidth: theme.spacings.unit(62.5),
  img: {
    mixBlendMode: 'multiply',
    transition: 'transform 0.2s',
    padding: '5% !important',
    '&:hover': {
      transform: 'scale3d(1.10, 1.10, 1.10)',
    },
  },
}));

export const StyledProductLink = styled(StyledLink)(({ theme }) => ({
  fontSize: theme.fonts.utility.size(6),
  textDecoration: 'none',
  color: theme.colors.primary,
  margin: `${theme.spacings.unit(3)} 0 ${theme.spacings.unit(3)} 0`,
  [theme.breakpointsMediaQuery.up('md')]: {
    fontSize: theme.fonts.utility.size(7),
  },
}));

export const ProductLinkWrapper = styled('span')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
}));

export const StyledVisibilityIcon = styled(VisibilityIcon)(({ theme }) => ({
  color: theme.colors.primary,
  marginLeft: theme.spacings.unit(2),
  width: theme.spacings.unit(5),
  height: theme.spacings.unit(5),
}));
