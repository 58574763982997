import { styled } from '@mui/material';
import { ContentWrapper } from '../../../styles/shared';

export const StyledContentWrapper = styled(ContentWrapper)(({ theme }) => ({
  padding: 0,
  height: '100%',
  [theme.breakpointsMediaQuery.up('md')]: {
    padding: `${theme.spacings.unit(4)} ${theme.spacings.unit(12)}`,
    borderTop: `${theme.spacings.unit(1 / 4)} solid ${theme.colors.primary}`,
    borderRight: `${theme.spacings.unit(1 / 4)} solid ${theme.colors.primary}`,
    borderBottom: `${theme.spacings.unit(1 / 4)} solid ${theme.colors.primary}`,
  },
}));

export const CarouselWrapper = styled('div')(({ theme }) => ({
  padding: `${theme.spacings.unit(4)} 0`,
  marginLeft: theme.spacings.unit(9),
  marginRight: theme.spacings.unit(9),
  '& li': {
    width: '100% !important',
    [theme.breakpointsMediaQuery.up('xl')]: {
      width: '1151px !important',
    },
  },
  [theme.breakpointsMediaQuery.up('md')]: {
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    height: `calc(100% - ${theme.spacings.unit(36 / 4)})`,
    '.alice-carousel': {
      height: '100%',
      [`
        & > div,
        .alice-carousel__wrapper,
        .alice-carousel__stage,
        .alice-carousel__stage-item __active __target,
        .MuiGrid-root
      `]: {
        height: '100%',
      },
      '.alice-carousel__stage': {
        padding: 0,
        margin: 0,
      },
    },

    '.alice-carousel__stage': {
      display: 'flex',
    },
    '.alice-carousel__stage-item': {
      flex: '1 0 auto',
      height: 'auto',
      '& > div': {
        height: '100%',
      },
    },
  },
}));
