import React, { useEffect, useRef, useState } from 'react';
import {
  CarouselWrapper,
  StyledContentWrapper,
} from './CarouselProdPromo.style';
import { Responsive } from 'react-alice-carousel';
import CardSliderProducts from '../../cards/card-slider-products/CardSliderProducts';
import BaseCarousel from '../base-carousel/BaseCarousel';
import { useTheme } from '../../../theme';
import { useWindowSize } from 'usehooks-ts';
import Repeater from '../../repeater/Repeater';
import PaginationArrows from '../../pagination-arrows/PaginationArrows';
import {
  HideOnDesktop,
  HideOnMobile,
} from 'packages/corporate-ui/src/styles/shared';
import PaginationArrowsMobile from '../../pagination-arrows-mobile/PaginationArrowsMobile';
import { Category, SliderConfigs } from '../../../models/domain/interfaces';
import {
  defaultSliderConfigs,
  fromHumanNumberOfColumnsToRepeaterNumberOfColumns,
} from '../../../utils/carousels';

interface Card {
  category?: string;
  title?: string;
  button?: any;
  image?: any;
}

interface ProductCard extends Card {
  categoriesCalculatedInfo: Category[][];
}

interface ProdPromoCarouselProps {
  cards: Card[] | ProductCard[];
  itemPerPage?: Responsive;
  sliderConfigs?: SliderConfigs;
  isPrerenderRequest: boolean;
}

const ProdPromoCarousel: React.FC<ProdPromoCarouselProps> = ({
  sliderConfigs = defaultSliderConfigs,
  ...props
}: ProdPromoCarouselProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeIndexMobile, setActiveIndexMobile] = useState(0);
  const [columns, setColumns] = useState<number>(4);
  const [spacing, setSpacing] = useState<number>(6);
  const { state: themeState } = useTheme();
  const ref = useRef();
  const refMobile = useRef();

  const slidePrev = () => {
    (ref?.current as any)?.current?.slideTo?.(activeIndex - 1);
    //setActiveIndex(activeIndex - 1);
  };
  const slideNext = () => {
    (ref?.current as any)?.current?.slideTo?.(activeIndex + 1);
    //setActiveIndex(activeIndex + 1)
  };
  const slidePrevMobile = () => {
    (refMobile?.current as any)?.current?.slideTo?.(activeIndexMobile - 1);
    //setActiveIndex(activeIndex - 1);
  };
  const slideNextMobile = () => {
    (refMobile?.current as any)?.current?.slideTo?.(activeIndexMobile + 1);
    //setActiveIndex(activeIndex + 1)
  };
  const syncActiveIndex = ({ item }: any) => setActiveIndex(item);
  const syncActiveIndexMobile = ({ item }: any) => setActiveIndexMobile(item);

  const { width } = useWindowSize();

  useEffect(() => {
    setActiveIndex(0);
    setActiveIndexMobile(0);
    if (width < themeState.theme.breakpointsMediaQuery.values.ssm) {
      setSpacing(0);
    }

    switch (true) {
      case width > themeState.theme.breakpointsMediaQuery.values.lgl:
        setColumns(
          sliderConfigs.numberOfElementsXL ||
            (defaultSliderConfigs.numberOfElementsXL as number)
        );
        setSpacing(6);
        break;
      case width > themeState.theme.breakpointsMediaQuery.values.lg:
        setColumns(
          sliderConfigs.numberOfElementsLG ||
            (defaultSliderConfigs.numberOfElementsLG as number)
        );
        setSpacing(6);
        break;
      case width > themeState.theme.breakpointsMediaQuery.values.md:
        setColumns(
          sliderConfigs.numberOfElementsMD ||
            (defaultSliderConfigs.numberOfElementsMD as number)
        );
        setSpacing(6);
        break;
      case width > themeState.theme.breakpointsMediaQuery.values.sm:
        setColumns(
          sliderConfigs.numberOfElementsSM ||
            (defaultSliderConfigs.numberOfElementsSM as number)
        );
        setSpacing(6);
        break;
      case width > themeState.theme.breakpointsMediaQuery.values.xs:
        setColumns(
          sliderConfigs.numberOfElementsXS ||
            (defaultSliderConfigs.numberOfElementsXS as number)
        );
        setSpacing(3.5);
        break;

      default:
        setColumns(
          sliderConfigs.numberOfElementsXS ||
            (defaultSliderConfigs.numberOfElementsXS as number)
        );
        setSpacing(3.5);
        break;
    }
  }, [
    sliderConfigs.numberOfElementsLG,
    sliderConfigs.numberOfElementsMD,
    sliderConfigs.numberOfElementsSM,
    sliderConfigs.numberOfElementsXL,
    sliderConfigs.numberOfElementsXS,
    themeState.theme.breakpointsMediaQuery.values.lg,
    themeState.theme.breakpointsMediaQuery.values.lgl,
    themeState.theme.breakpointsMediaQuery.values.md,
    themeState.theme.breakpointsMediaQuery.values.sm,
    themeState.theme.breakpointsMediaQuery.values.ssm,
    themeState.theme.breakpointsMediaQuery.values.xs,
    width,
  ]);

  const repeaterRender = (items: any[]) => {
    return items.reduce((acc, curr, i) => {
      if (!acc || !acc[Math.floor(i / columns)]) {
        acc = {
          ...acc,
          [Math.floor(i / columns)]: [curr],
        };
      } else {
        acc[`${Math.floor(i / columns)}`].push(curr);
      }
      return acc;
    }, {} as any);
  };

  const cardsMapper = (cards: ProductCard[]) => {
    const result = cards
      ?.filter((card: ProductCard) => {
        // verify the integrity of item becasuse sometime products are not working
        const { title, button, image, categoriesCalculatedInfo } = card || {};

        return (
          !!title &&
          !!button &&
          !!image &&
          !!categoriesCalculatedInfo &&
          categoriesCalculatedInfo.length > 0 &&
          categoriesCalculatedInfo[0].length > 0
        );
      })
      ?.map((card, i) => {
        return {
          key: i,
          category: card.category,
          name: card.title,
          button: card.button,
          image: card.image,
          categoriesCalculatedInfo: card.categoriesCalculatedInfo,
          isPrerenderRequest: props.isPrerenderRequest,
        };
      });
    return result;
  };

  const carouselItems = Object.values(
    repeaterRender(
      (props.cards &&
        Array.isArray(props.cards) &&
        cardsMapper(props.cards as ProductCard[])) ||
        []
    )
  )?.map((items: any, i) => (
    <Repeater
      key={i}
      spacing={spacing}
      cols={{
        xs: fromHumanNumberOfColumnsToRepeaterNumberOfColumns(
          sliderConfigs.numberOfElementsXS ||
            (defaultSliderConfigs.numberOfElementsXS as number)
        ),
        sm: fromHumanNumberOfColumnsToRepeaterNumberOfColumns(
          sliderConfigs.numberOfElementsSM ||
            (defaultSliderConfigs.numberOfElementsSM as number)
        ),
        md: fromHumanNumberOfColumnsToRepeaterNumberOfColumns(
          sliderConfigs.numberOfElementsMD ||
            (defaultSliderConfigs.numberOfElementsMD as number)
        ),
        lg: fromHumanNumberOfColumnsToRepeaterNumberOfColumns(
          sliderConfigs.numberOfElementsLG ||
            (defaultSliderConfigs.numberOfElementsLG as number)
        ),
        xl: fromHumanNumberOfColumnsToRepeaterNumberOfColumns(
          sliderConfigs.numberOfElementsXL ||
            (defaultSliderConfigs.numberOfElementsXL as number)
        ),
      }}
      data={items}
      props={{ isPrerenderRequest: props.isPrerenderRequest }}
      component={CardSliderProducts}
    />
  ));

  return (
    <StyledContentWrapper>
      <HideOnMobile>
        {props.cards.length > 1 && (
          <PaginationArrows
            index={activeIndex}
            label={false}
            totalItems={carouselItems.length}
            onLeftArrowClick={slidePrev}
            onRightArrowClick={slideNext}
          />
        )}
        <CarouselWrapper>
          <BaseCarousel
            {...props}
            dotsAlignment={'left'}
            responsive={props.itemPerPage}
            mouseTracking={true}
            disableButtonsControls
            disableDotsControls
            activeIndex={activeIndex}
            onSlideChanged={syncActiveIndex}
            items={carouselItems}
            autoPlay={sliderConfigs.autoPlay}
            autoPlayInterval={sliderConfigs.autoPlayInterval}
            animationDuration={sliderConfigs.animationDuration}
            animationType={sliderConfigs.animationType}
            infinite={sliderConfigs.infinite}
            refFromParentCb={(refFromChild: any) => {
              ref.current = refFromChild;
            }}
          />
        </CarouselWrapper>
      </HideOnMobile>
      <HideOnDesktop>
        <PaginationArrowsMobile
          index={activeIndexMobile}
          totalItems={carouselItems.length}
          onLeftArrowClick={slidePrevMobile}
          onRightArrowClick={slideNextMobile}
        >
          <CarouselWrapper>
            <BaseCarousel
              {...props}
              responsive={props.itemPerPage}
              mouseTracking={true}
              disableButtonsControls
              disableDotsControls
              activeIndex={activeIndexMobile}
              onSlideChanged={syncActiveIndexMobile}
              items={carouselItems}
              dotsAlignment={'center'}
              autoPlay={sliderConfigs.autoPlay}
              animationDuration={sliderConfigs.animationDuration}
              infinite={sliderConfigs.infinite}
              refFromParentCb={(refFromChild: any) => {
                refMobile.current = refFromChild;
              }}
            />
          </CarouselWrapper>
        </PaginationArrowsMobile>
      </HideOnDesktop>
    </StyledContentWrapper>
  );
};

export default ProdPromoCarousel;
